<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/turmas'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/turmas')"
              >Turmas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/turma/' +
                    $route.params.id_turma
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/turma/' +
                  $route.params.id_turma)"
              >Turma {{ fastTurma.sigla_turma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                v-for="programa in fastTurma.programas"
                :key="programa.id_programa_turma"
                class="aluno_font_color"
                href="#"
              >
                <span
                  v-if="
                    programa.id_programa_turma ==
                      $route.params.id_programa_turma
                  "
                >{{ programa.sigla_programa }} - Matriz curricular</span>
              </a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Turma
                  <span>{{ fastTurma.sigla_turma }}</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-4">
                  <transition name="slide-fade">
                    <div
                      v-if="fastTurma.id_turma"
                      class="card shadow mb-4"
                    >
                      <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">
                          Detalhes da turma
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <p class="m-0">
                                  Ativo:
                                  <span
                                    v-if="fastTurma.ativo == 'S'"
                                    class="text-success"
                                  >Sim</span>
                                  <span
                                    v-else
                                    class="text-info"
                                  >Não</span>
                                </p>
                                <p class="m-0">
                                  Descrição:
                                  <span class="text-info">{{
                                    fastTurma.descricao_turma
                                  }}</span>
                                </p>
                                <p
                                  v-if="fastTurma.etapa"
                                  class="m-0"
                                >
                                  Etapa:
                                  <span class="text-info">{{
                                    fastTurma.etapa
                                  }}</span>
                                </p>                              
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <p class="m-0">
                                  Data de modificação:
                                  <span
                                    v-if="fastTurma.data_modifica"
                                    class="text-info"
                                  >{{
                                    new Date(fastTurma.data_modifica)
                                      .toLocaleString()
                                      .split(" ")[0]
                                  }}</span>
                                  <span
                                    v-else
                                    class="text-info"
                                  >Nunca</span>
                                </p>
                                <p class="m-0">
                                  Data de cadastro:
                                  <span class="text-info">{{
                                    new Date(fastTurma.data_registro)
                                      .toLocaleString()
                                      .split(" ")[0]
                                  }}</span>
                                </p>                             
                                <p class="m-0">
                                  Turno:
                                  <span
                                    v-if="fastTurma.turno == 1"
                                    class="text-info"
                                  >Manhã</span>
                                  <span
                                    v-else-if="fastTurma.turno == 2"
                                    class="text-info"
                                  >Tarde</span>
                                  <span
                                    v-else-if="fastTurma.turno == 3"
                                    class="text-info"
                                  >Noite</span>
                                  <span
                                    v-else
                                    class="text-info"
                                  >Indefinido</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-2">
                  <transition name="slide-fade">
                    <div
                      v-if="fastTurma.programas.length"
                      class="card shadow mb-4"
                    >
                      <div class="card-header py-3">
                        <h6
                          v-for="programa in fastTurma.programas"
                          :key="programa.id_programa_turma"
                          class="m-0 font-weight-bold text-primary"
                        >
                          <span
                            v-if="
                              programa.id_programa_turma ==
                                $route.params.id_programa_turma
                            "
                          >{{ programa.sigla_programa }}</span>
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div
                            v-for="programa in fastTurma.programas"
                            :key="programa.id_programa_turma"
                            class="col-12"
                          >
                            <div
                              v-if="
                                programa.id_programa_turma ==
                                  $route.params.id_programa_turma
                              "
                              class="row"
                            >
                              <div class="col-sm-12 col-md-12 col-lg-4">
                                <p class="m-0">
                                  Ano:
                                  <input
                                    type="text"
                                    class="form-control"
                                    :value="programa.ano"
                                    readonly
                                  >
                                </p>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-4">
                                <p class="m-0">
                                  Data de início:
                                  <span class="text-info">
                                    <input
                                      type="date"
                                      class="form-control"
                                      :value="programa.data_inicio"
                                      readonly
                                    >
                                  </span>
                                </p>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-4">
                                <p class="m-0">
                                  Data final:
                                  <input
                                    type="date"
                                    class="form-control"
                                    :value="programa.data_final"
                                    readonly
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="row">
                <transition name="slide-fade">
                  <div v-if="fastNavegacao.exibirMatriz" class="col-12 mt-2">
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Matriz curricular
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr>
                                  <th class="text-center">
                                    <small
                                      class="font-weight-bold"
                                    >Sequência</small>
                                  </th>
                                  <th>
                                    <small
                                      class="font-weight-bold"
                                    >Unidade curricular</small>
                                  </th>
                                  <th class="text-center">
                                    <small
                                      class="font-weight-bold"
                                    >Carga horária</small>
                                  </th>
                                  <th class="text-center">
                                    <small
                                      class="font-weight-bold"
                                    >Data de início</small>
                                  </th>
                                  <th class="text-center">
                                    <small
                                      class="font-weight-bold"
                                    >Data fim</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastCursosProgramasLoading">
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                    v-html="fastLoadingDados()"
                                  />
                                </tr>
                              </tbody>
                              <tbody v-else-if="fastCursosProgramas.length">
                                <tr
                                  v-for="curso in fastCursosProgramas"
                                  :key="curso.id_curso"
                                >
                                  <td class="text-center align-middle">
                                    <small>{{ curso.sequencia }} </small>                                  
                                  </td>
                                  <td class="align-middle">
                                    <small>
                                      <a
                                        :href="
                                          '/plataforma/' +
                                            $route.params.id_plataforma +
                                            '/secretaria/unidade-curricular/' +
                                            curso.id_curso
                                        "
                                      >
                                      <span v-if="curso.nome_curso">{{ curso.nome_curso }}</span>
                                      <span v-else>{{ curso.nome_curso_mae }}</span>
                                      </a>
                                    </small>
                                  </td>
                                  <td class="text-center align-middle">
                                    <small v-if="curso.carga_horaria">{{ curso.carga_horaria }} h</small>
                                    <small v-else>{{ curso.carga_horaria_mae }} h</small>
                                  </td>
                                  <td class="text-center align-middle">
                                    <small v-if="curso.data_inicio">{{
                                      curso.data_inicio.split("-")[2] +
                                        "/" +
                                        curso.data_inicio.split("-")[1] +
                                        "/" +
                                        curso.data_inicio.split("-")[0]
                                    }}</small>
                                  </td>
                                  <td class="text-center align-middle">
                                    <small v-if="curso.data_final">{{
                                      curso.data_final.split("-")[2] +
                                        "/" +
                                        curso.data_final.split("-")[1] +
                                        "/" +
                                        curso.data_final.split("-")[0]
                                    }}</small>
                                  </td>
                                  <td class="text-center align-middle">
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="exibeProgramacaoAula(curso)"
                                    >
                                      <small>Programação</small>
                                    </button>
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="exibeModalExibirCalendarioUC(curso)"
                                    >
                                      <small>Calendário</small>
                                    </button>
                                    
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                  >
                                    Nenhuma unidade curricular cadastrada
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>

                <transition name="slide-fade">
                  <div v-if="fastNavegacao.exibirProgramacao" class="col-12 mt-2">
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              UC - {{fastProgramaCursoEditar.nome_curso}} - Programação de aula
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          
                        </div>  
                        <div class="row mt-4">
                          
                          <div v-if="fastCursoAulas.loading" class="col-sm-12 col-md-12 col-lg-12 mt-4 text-center">
                            <h4><b-icon
                              icon="gear-fill"
                              animation="spin"
                            /> Carregando aulas
                            </h4>
                          </div>
                          <div v-else-if="fastCursoAulas.filtro.length" class="col-sm-12 col-md-12 col-lg-12">
                              <div class="row">
                                <div class="col-12 text-center mt-2 mb-4">
                                  <h4>{{fastCursoAulas.filtro.length}} aulas encontradas</h4> 
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6 mb-4 d-flex align-items-end">
                                  <input type="text" class="form-control" placeholder="Filtrar pelo nome da aula" @keyup="filtraAulas">
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                                  <button class="btn btn-sm btn-primary btn-block" @click="showModal('modalExibirCalendarioInicioUC')">
                                    <small>Definir data de início da UC</small>
                                  </button>
                                  <input v-if="fastProgramaCursoEditar.data_inicio" type="text" class="form-control text-center mt-2" readonly :value="formataDataT(fastProgramaCursoEditar.data_inicio)">                            
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                                  <button class="btn btn-sm btn-primary btn-block" @click="showModal('modalExibirCalendarioFinalUC')">
                                    <small>Definir data final da UC</small>
                                  </button>
                                  <input v-if="fastProgramaCursoEditar.data_final" type="text" class="form-control text-center mt-2" readonly :value="formataDataT(fastProgramaCursoEditar.data_final)">  
                                </div>
                              </div>
                              
                              <div class="row">
                                <div v-for="(e, index) in fastCursoAulas.filtro" :key="index" class="col-sm-12 col-md-12 col-lg-12 mb-4">

                                  <div class="fast-cursor-pointer p-4 rounded border text-info" @click="exibirDetalhesAula(e)">
                                    <h4 class="mt-2">
                                    {{e.sequencia}} - {{e.titulo_aula}} <span v-if="e.data_programado">- {{formataDataT(e.data_programado)}}</span>                                    
                                    </h4>
                                    <h6 v-if="!e.id_programacao_aula" class="text-warning">                                      
                                      Sem programação configurada <b-icon icon="exclamation-circle-fill" font-scale="1" variant="warning"></b-icon>
                                    </h6>
                                  </div>

                                  <div v-show="e.exibirDetalhes" class="row">
                                    <div class="col-12 p-4">
                                      <div class="row">
                                        <div class="col-12">
                                          <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-6">
                                              <h4 class="text-info">Detalhes da aula {{e.sequencia}}</h4>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                                              <button v-if="fastProgramaCursoEditar.id_plano_ensino" class="btn btn-sm btn-secondary ml-1 mr-1" @click="showModal('modalExibirPlanoEnsino')">
                                                <small>Exibir plano de ensino</small>
                                              </button>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-12 pl-4 pr-4">
                                          <div class="row">
                                            <div class="col-12 border">
                                              <div class="row">

                                                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                                                  <label class="font-weight-bold text-secondary">Conteúdo pré-definido</label>                                
                                                  <quill-editor
                                                    v-if="e.conteudo_programatico"
                                                    style="background-color: #eee"
                                                    :disabled='true'
                                                    :content="e.conteudo_programatico"
                                                    :options="editorOptionAula"
                                                  />
                                                  <quill-editor
                                                    v-else
                                                    style="background-color: #eee"
                                                    :disabled='true'
                                                    :content="'Nenhum conteúdo programático cadastrado'"
                                                    :options="editorOptionAula"
                                                  />
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="font-weight-bold text-secondary">Conteúdo programado</label>
                                                    </div>                                  
                                                  </div>                                
                                                  <quill-editor
                                                    v-model="e.conteudo_programado"
                                                    @change="e.alteracao_pendente = true"
                                                    :options="editorOption"
                                                  />                               
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-4 text-center mt-4">
                                                  <button class="btn btn-sm btn-secondary btn-block pb-0 pt-0 mb-2" @click="exibeModalExibirCalendarioDataAula(e)">
                                                    <small>Definir data programada</small>
                                                  </button>
                                                  <input v-if="e.data_programado" type="text" class="form-control text-center" readonly :value="formataDataT(e.data_programado)">
                                                  <input v-else type="text" class="form-control text-center" readonly value="Data livre">
                                                  
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                                                  <label class="font-weight-bold text-secondary">Horário </label> <button v-if="e.hora_programada" @click="e.hora_programada = null; e.alteracao_pendente = true" class="btn btn-sm pb-0 pt-0 btn-secondary"> <small>Remover</small></button>
                                                  <input v-if="e.data_programado" type="time" class="form-control text-center" v-model="e.hora_programada" @change="e.alteracao_pendente = true">
                                                  <input v-else type="time" class="form-control text-center" readonly :value="e.hora_programada">
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                                                  <label class="font-weight-bold text-secondary">Tipo de aula</label>
                                                  <select class="form-control" v-model="e.tipo_aula" @change="e.alteracao_pendente = true">
                                                    <option value="S">Síncrona</option>
                                                    <option value="A">Assíncrona</option>
                                                    <option value="P">Presencial</option>
                                                  </select>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 text-center">
                                                  <label class="font-weight-bold text-secondary">
                                                    Sala ao vivo 
                                                    <button v-if="e.live && !e.id_live" class="btn btn-sm pb-0 pt-0 btn-secondary" @click="exibeModalCriarLive(e)"> <small>+ Agendar manualmente</small></button> 
                                                    <span v-else-if="e.live && e.id_live" class="text-info" v-b-tooltip.hover title="Para editar acesse minhas lives"> <small>(Agendada)</small></span>
                                                  </label>
                                                  <div class="fd-app-curso-aulas mt-0">
                                                    <div class="fd-app-curso-aulas-list mt-0">
                                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                          <div class="col-12 text-center">
                                                            <label class="switch">
                                                              <input
                                                                v-model="e.live"
                                                                @change="e.alteracao_pendente = true"
                                                                type="checkbox"
                                                              >
                                                              <span class="slider round" />
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>  
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 text-center">
                                                  <label class="font-weight-bold text-secondary">Avaliação da UC</label>
                                                  <div class="fd-app-curso-aulas mt-0">
                                                    <div class="fd-app-curso-aulas-list mt-0">
                                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                          <div class="col-12 text-center">
                                                            <label class="switch">
                                                              <input
                                                                v-model="e.avaliacao"
                                                                @change="e.alteracao_pendente = true"
                                                                type="checkbox"
                                                              >
                                                              <span class="slider round" />
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>  
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-4 text-center">
                                                  <div class="row">
                                                    <div
                                                      v-if="e.alteracao_pendente"
                                                      class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                                                    >
                                                      <button
                                                        class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                                        @click.prevent="atualizaProgramacaoAula(e)"
                                                      >
                                                        <small>
                                                          <i
                                                            class="fa fa-floppy-o"
                                                            aria-hidden="true"
                                                          />
                                                          &nbsp;Salvar alterações
                                                        </small>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                                                  <label class="font-weight-bold text-secondary d-flex align-items-center">Professor responsável <button class="btn btn-sm btn-primary pb-0 pt-0 ml-2" @click="exibeModalProfessoresPlataforma(e)"> <small>+ Adicionar</small> </button></label>
                                                  <div class="row mt-4">
                                                    <div v-if="fastProfessoresProgramacaoLoading" class="col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                                                      <h6>Carregando professores...</h6>
                                                    </div>
                                                    <div v-else-if="e.professores.length" class="col-sm-12 col-md-12 col-lg-3  mb-4 text-center" v-for="(p, index) in e.professores" :key="index">
                                                      <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(p.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />                                                      
                                                      <div>Prof. {{p.nome_razao ? p.nome_razao : 'Sem nome'}} {{p.sobrenome_fantasia}} </div>
                                                      <div>{{p.email}}</div>
                                                      <div class="mt-2">
                                                        <button class="btn btn-sm btn-danger mb-2" @click="exibeModalExcluirProfessor(p)"> <small>- Remover</small>  </button>
                                                      </div>
                                                    </div>
                                                    <div v-else class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                                      <span>Nenhum professor adicionado</span>
                                                    </div>
                                                  </div>                                      
                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </div>

                            </div>
                          </div>                              

                          </div>
                          <div v-else class="col-sm-12 col-md-12 col-lg-12 mt-4 text-center">
                            <h4>Nenhuma aula encontrada na UC</h4>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <button
                              href="#"
                              type="button"
                              class="btn btn-secondary mr-1"
                              @click="alteraFastNavegacao('exibirMatriz')"
                            >
                              Voltar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>


              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria/turma/' +
                      $route.params.id_turma
                  "
                  @click.prevent="$router.push('/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/turma/' +
                    $route.params.id_turma)"                   
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalExibirPlanoEnsino"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Plano de ensino da UC - {{fastProgramaCursoEditar.nome_curso}}</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExibirPlanoEnsino')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label class="font-weight-bold">Componentes associados</label>
            </div>
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm table-bordered">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th><small class="font-weight-bold">Sequência do componente</small></th>
                    <th><small class="font-weight-bold">Nome do componente</small></th>                    
                  </tr>
                </thead>
                <tbody v-if="fastComponentesAssociadosLoading">
                  <tr>
                    <td
                      colspan="2"
                      class="text-center"
                    >
                      Carregando...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastComponentesAssociados.length">
                  <tr
                    v-for="(e, index) in fastComponentesAssociados"
                    :key="index"
                  >
                    <td class="align-middle text-center">
                      <small>{{ e.sequencia }}</small>                                  
                    </td>
                    <td class="align-middle text-center">
                      <small>{{ e.unidade_curricular_tematica }}</small>                                  
                    </td>  
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="2"
                      class="text-center"
                    >
                      <small>Nenhum componente associado</small>                                        
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">                  
              <label class="font-weight-bold">Competências</label>
              <div
                class="row mt-0"
              >
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-bordered">
                    <thead class="thead-dark">
                      <tr class="text-center">
                        <th><small class="font-weight-bold">Competência</small></th>
                      </tr>
                    </thead>
                    <tbody v-if="fastComponente.competencias.loading">
                      <tr>
                        <td
                          colspan="1"
                          class="text-center"
                        >
                          Carregando...
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="fastComponente.competencias.total.length">
                      <tr
                        v-for="(e, index) in fastComponente.competencias.total"
                        :key="index"
                      >
                        <td class="align-middle">
                          <small>{{ e.nome_competencia }}</small>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          colspan="1"
                          class="text-center"
                        >
                          <small>Nenhuma competência associada</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </div>
              <div v-if="fastPlanoEnsino.desc_competencia">   
                <div class="col-12">
                  <label class="font-weight-bold">Detalhes da competência</label>
                </div>                           
                <div
                  v-if="fastComponente.competencias.loading"
                  class="text-center mt-2"
                >
                  <b-icon
                    icon="gear-fill"
                    animation="spin"
                  /> Carregando competências
                </div>
                <quill-editor
                  v-else
                  style="background-color: #eee"
                  :disabled='true'
                  :content="fastPlanoEnsino.desc_competencia"
                  :options="editorOptionAula"
                />
              </div>
            </div>   
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label class="font-weight-bold">Indicadores</label>
              <div
                class="row mt-0"
              >
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-bordered">
                    <thead class="thead-dark">
                      <tr class="text-center">
                        <th><small class="font-weight-bold">Indicadores</small></th>
                      </tr>
                    </thead>
                    <tbody v-if="fastComponente.indicadores.loading">
                      <tr>
                        <td
                          colspan="1"
                          class="text-center"
                        >
                          Carregando...
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="fastComponente.indicadores.total.length">
                      <tr
                        v-for="(e, index) in fastComponente.indicadores.total"
                        :key="index"
                      >
                        <td class="align-middle">
                          <small>{{ e.nome_indicador }}</small>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          colspan="1"
                          class="text-center"
                        >
                          <small>Nenhum indicador associado</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </div>
              <div v-if="fastPlanoEnsino.desc_indicadores">
                <div class="col-12">
                  <label class="font-weight-bold">Detalhes dos indicadores</label>
                </div>
                <div
                  v-if="fastComponente.indicadores.loading"
                  class="text-center mt-2"
                >
                  <b-icon
                    icon="gear-fill"
                    animation="spin"
                  /> Carregando indicadores
                </div>
                <quill-editor
                  v-else
                  style="background-color: #eee"
                  :disabled='true'
                  :content="fastPlanoEnsino.desc_indicadores"
                  :options="editorOptionAula"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label class="font-weight-bold">Bibliografias</label>
              <div
                class="row mt-0"
              >
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm">
                    <thead class="thead-dark">
                      <tr class="text-center">
                        <th class="text-center">
                          <small class="font-weight-bold">ISBN</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Nome do livro</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Autor(a)</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">ano</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastComponente.bibliografias.loading">
                      <tr>
                        <td
                          colspan="4"
                          class="text-center"
                        >
                          Carregando...
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="fastComponente.bibliografias.total.length">
                      <tr
                        v-for="(e, index) in fastComponente.bibliografias.total"
                        :key="index"
                      >
                        <td class="align-middle text-center">
                          <small v-if="e.isbn">
                            <span v-if="e.isbn.trim()">{{ e.isbn }}</span>
                            <span v-else>Não registrado</span>
                          </small>
                          <small v-else>Não registrado</small>
                        </td>
                        <td class="align-middle text-center">
                          <small>{{ e.nome_livro }}</small>
                        </td>
                        <td class="align-middle text-center">
                          <small>{{ e.autora }}</small>
                        </td>
                        <td class="align-middle text-center">
                          <small>{{ e.ano }}</small>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          colspan="4"
                          class="text-center"
                        >
                          <small>Nenhuma bibliografia associada</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </div>
              <div v-if="fastPlanoEnsino.desc_bibliografia">
                <div class="col-12">
                  <label class="font-weight-bold">Detalhes da bibliografia</label>
                </div>
                <div
                  v-if="fastComponente.bibliografias.loading"
                  class="text-center mt-2"
                >
                  <b-icon
                    icon="gear-fill"
                    animation="spin"
                  /> Carregando bibliografias
                </div>
                <quill-editor
                  v-else
                  style="background-color: #eee"
                  :disabled='true'
                  :content="fastPlanoEnsino.desc_bibliografia"
                  :options="editorOptionAula"
                />
              </div>
            </div>
          </div>
          <div v-if="fastPlanoEnsino.desc_objeto_conhecimento" class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label class="font-weight-bold">Objeto de conhecimento</label>
              <quill-editor
                style="background-color: #eee"
                :disabled='true'
                :content="fastPlanoEnsino.desc_objeto_conhecimento"
                :options="editorOptionAula"
              />
            </div> 
          </div>
          <div v-if="fastPlanoEnsino.desc_habilidade" class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label class="font-weight-bold">Habilidades</label>
              <quill-editor
                style="background-color: #eee"
                :disabled='true'
                :content="fastPlanoEnsino.desc_habilidade"
                :options="editorOptionAula"
              />
            </div>
          </div>
          <div v-if="fastPlanoEnsino.desc_metodologia" class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label class="font-weight-bold">Metodologias</label>
              <quill-editor
                style="background-color: #eee"
                :disabled='true'
                :content="fastPlanoEnsino.desc_metodologia"
                :options="editorOptionAula"
              />
            </div>
          </div>

        </div>
      </div>
    </modal>
   

    <modal
      name="modalExibirCalendarioInicioUC"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Defina a data do início da UC</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExibirCalendarioInicioUC')"
          >
            <b-icon-arrow-return-left /> Voltar
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="container">
            <fast-calendar :anoBase="anoBaseCalendario" :retornaDataSelecionada="definirDataInicioUC" />
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalExibirCalendarioInicioUC')"
              >
                <b-icon-arrow-return-left /> Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibirCalendarioFinalUC"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Defina a data do final da UC</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExibirCalendarioFinalUC')"
          >
            <b-icon-arrow-return-left /> Voltar
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="container">
            <fast-calendar :anoBase="anoBaseCalendario" :retornaDataSelecionada="definirDataFinalUC" />
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalExibirCalendarioFinalUC')"
              >
                <b-icon-arrow-return-left /> Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibirCalendarioDataAula"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Defina a data programada da aula</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExibirCalendarioDataAula')"
          >
            <b-icon-arrow-return-left /> Voltar
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <button @click="removerDataAula()" class="btn btn-sm pb-0 pt-0 btn-primary"> <small>Remover data</small></button>
            </div>            
          </div>
          <div class="container mt-4">
            <fast-calendar :anoBase="anoBaseCalendario" :retornaDataSelecionada="definirDataAula" :programacaoTurma="fastCursoAulas.total" />
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalExibirCalendarioDataAula')"
              >
                <b-icon-arrow-return-left /> Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalProfessoresPlataforma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Professores da plataforma</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalProfessoresPlataforma')"
          >
            <b-icon-arrow-return-left /> Voltar
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista de professores da plataforma ({{ fastProfessoresTotal.length }})
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Filtrar por nome ou email"
                        @keyup="filtraProfessores"
                      >
                    </div>
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm">
                        <thead class="thead-dark">
                          <tr>
                            <th><small class="font-weight-bold">Nome</small></th>
                            <th><small class="font-weight-bold">Email</small></th>
                            <th class="text-center">
                              <small class="font-weight-bold">Aceito</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Ativo</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Adicionado em</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastProfessoresLoading">
                          <tr>
                            <td
                              colspan="6"
                              class="text-center"
                            >
                            Carregando professores...
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else-if="fastProfessoresPaginacao.length">
                          <tr
                            v-for="(e, index) in fastProfessoresPaginacao"
                            :key="index"
                          >
                            <td class="align-middle">
                              <small>{{ e.first_name + " " + e.last_name }}</small>                                  
                            </td>
                            <td class="align-middle">
                              <small>{{ e.email }}</small>                                  
                            </td>
                            <td class="align-middle text-center">
                              <small
                                v-if="e.ativo == 'S'"
                                class="badge badge-success mr-2"
                              >sim</small>
                              <small
                                v-else
                                class="badge badge-danger mr-2"
                              >não</small>                                  
                            </td>
                            <td class="align-middle text-center">
                              <small
                                v-if="e.ativo == 'S'"
                                class="badge badge-success mr-2"
                              >sim</small>
                              <small
                                v-else
                                class="badge badge-danger mr-2"
                              >Não</small>                               
                            </td>
                            <td class="align-middle text-center">
                              <small v-if="e.data_convite_envio">{{ new Date(e.data_convite_envio).toLocaleDateString() }}</small>  
                              <small v-else>Sem registro</small>                              
                            </td>                           
                            <td class="text-center align-middle">       
                              <button
                                class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                @click="adicionaProfessorProgramacao(e)"
                              >
                                <small>+ Adicionar</small>
                              </button>   
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="6"
                              class="text-center"
                            >
                              Nenhum Professor cadastrado na plataforma
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="20"
                        :items="fastProfessoresFiltro"
                        @changePage="fastProfessoresPaginacao = $event"
                      />
                    </div>
                  </div>
                </div>
              </div>  
            </div>            
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalProfessoresPlataforma')"
              >
                <b-icon-arrow-return-left /> Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirProfessor"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Remover professor da aula?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirProfessor')"
          >
            <b-icon-arrow-return-left /> Voltar
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-sm btn-danger" @click="excluirProfessorProgramacao()">
                Confirmar
              </button>             
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibirCalendarioUC"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Calendário da turma {{fastTurma.sigla_turma}} na UC {{fastProgramaCursoEditar.nome_curso}}</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExibirCalendarioUC')"
          >
            <b-icon-arrow-return-left /> Voltar
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="container">
            <fast-calendar :anoBase="anoBaseCalendario" :programacaoTurma="fastCursoAulas.total" :exibirFeriados="true" :retornaDataSelecionada="diaClicadoTurma" />
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalExibirCalendarioUC')"
              >
                <b-icon-arrow-return-left /> Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarLive"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Agendamento manual</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-3">
              <label for>Título da live</label>
              <input
                :value="fastLiveNova.titulo_live"
                type="text"
                class="form-control"
                readonly
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <label for>Detalhes da live</label>
              <textarea
                v-model="fastLiveNova.detalhes"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3">
              <label>Tipo</label>
              <select
                v-model="fastLiveNova.tipo"
                class="form-control"
              >
                <option value="J">
                  FastEAD (Nova)
                </option>
                <option value="Z">
                  Zoom
                </option>
                <option value="M">
                  Google Meet
                </option>
                <option value="A">
                  Telemedicina (tarifada)
                </option>
              </select>
            </div>
            <div
              v-if="fastLiveNova.tipo == 'M'"
              class="col-12"
            >
              <label for>URL do Google Meet</label>
              <input
                v-model="fastLiveNova.API_TOKEN"
                type="text"
                class="form-control"
                placeholder="Gere a URL do Google Meet e insira aqui"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Data da live</label>
              <input
                :value="fastLiveNova.data_live.split('-')[2] + '/' + fastLiveNova.data_live.split('-')[1] + '/' + fastLiveNova.data_live.split('-')[0]"
                type="text"
                class="form-control"
                readonly
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Hora da liberação da live</label>
              <input
                :value="fastLiveNova.hora_live"
                type="text"
                class="form-control"
                readonly
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Duração da live</label>
              <input
                v-model="fastLiveNova.duracao_live"
                type="time"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Acesso</label>
              <select
                v-model="fastLiveNova.acesso"
                class="form-control"
              >
                <option value="R">
                  Restrito
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <label
                for
              >Participantes ({{ fastLiveNova.participantes.length }})</label>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Filtrar por nome"
                    @keyup="filtraUsuario"
                  >
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">
                          Nome do usuário
                        </th>
                        <th scope="col">
                          Email
                        </th>
                        <th
                          scope="col"
                          class="text-center"
                        >
                          Ativo
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                        >
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="pageOfUsuarios.length">
                      <tr
                        v-for="u in pageOfUsuarios"
                        :key="u.email"
                      >
                        <td>{{ u.nome_usuario }}</td>
                        <td>{{ u.email }}</td>
                        <td class="text-center">
                          <span class="text-success">{{
                            u.ativo == "S" ? "Ativo" : ""
                          }}</span>
                          <span class="text-warning">{{
                            u.ativo != "S" ? "Não ativo" : ""
                          }}</span>
                        </td>
                        <td class="text-center">
                          <button
                            v-if="
                              u.ativo == 'S' &&
                                !verificaUsuarioIncluido(u.id_usuario)
                            "
                            class="btn btn-sm btn-success btn-block"
                            title="Inserir participante"
                            @click="adicionaParticipantesLive(u)"
                          >
                            <small>
                              <b-icon-person-plus-fill /> Adicionar
                            </small>
                          </button>
                          <button
                            v-else-if="
                              u.ativo == 'S' &&
                                verificaUsuarioIncluido(u.id_usuario)
                            "
                            class="btn btn-sm btn-danger btn-block"
                            title="Remover participante"
                            @click="removeParticipantesLive(u.id_usuario)"
                          >
                            <small>
                              <b-icon-person-dash-fill /> Remover
                            </small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          class="text-center"
                          colspan="4"
                        >
                          Nenhum usuário disponível
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 text-center">
                  <Pagination
                    :page-size="5"
                    :items="usuariosFiltro"
                    @changePage="pageOfUsuarios = $event"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                  <label for>Tutor da live</label>
                  <select
                    v-if="fastLiveNova.participantes.length"
                    v-model="fastLiveNova.id_usuario_tutor"
                    class="form-control"
                  >
                    <option
                      v-for="participante in fastLiveNova.participantes"
                      :key="participante.id_usuario"
                      :value="participante.id_usuario"
                    >
                      {{ participante.nome_usuario }}
                    </option>
                  </select>
                  <select
                    v-else
                    class="form-control"
                    style="
                      background: #eee;
                      pointer-events: none;
                      touch-action: none;
                    "
                  >
                    <option>Escolha um participante</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2 mb-2">
              <div class="alert alert-info" role="alert">
                <p><b>Observação:</b></p>
                Não é preciso convidar alunos que estão inseridos na turma.
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarLive()"
                >
                  Agendar live
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>


    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import FastCalendar from "../components/FastSecretariaCalendario";

export default {
  name: "HomeInternoSecretariaTurmaPrograma",
  components: {
    quillEditor, FastCalendar, Pagination
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "100%" : "30%",
      modalWidthMd: this.isMobile() ? "100%" : "80%",
      modalWidthLg: this.isMobile() ? "100%" : "100%",
      fastNavegacao: {
        exibirMatriz: true,
        exibirProgramacao: false
      },
      fastTurma: {
        ativo: "",
        data_modifica: "",
        data_registro: "",
        descricao_turma: "",
        etapa: "",
        id_plataforma: 0,
        id_turma: "",
        id_usuario: "",
        id_usuario_modifica: "",
        nome_plataforma: "",
        sigla_turma: "...",
        turno: "",
        id_secretaria_curso: 0,
        programas: [],
      },
      // Cursos do programa
      fastCursosProgramas: [],
      fastCursosProgramasLoading: true,
      fastProgramaCursoEditar: {
        id_programa_turma_unidade: "",
        data_inicio: "",
        data_final: "",        
        id_programa_turma: "",
        id_secretaria_curso_programa_unidade: 0,
        id_plano_ensino: "",
        id_curso: 0
      },
      // Programação de aula
      fastCursoAulas: {
        total: [],
        filtro: [],
        loading: true
      },
      // Editor de texto
      editorOptionAula: {
        readOnly: true,
        modules: {
          toolbar: false
        },
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          ],
        },
      },   
      // Plano de ensino
      fastPlanoEnsino: {
        id_plano_ensino: 0,
        id_plataforma: this.$route.params.id_plataforma,
        desc_objeto_conhecimento: "",
        desc_habilidade: "",
        desc_competencia: "",
        desc_metodologia: "",
        desc_indicadores: "",
        desc_bibliografia: "",
        id_curso: 0,
        id_base_curricular_componente: "",
        id_usuario: "",
        data_registro: "",
        id_usuario_alteracao: "",
        data_ultima_modificacao: "",
        loading: true
      },
      fastComponente: {
        bibliografias: {
          total: [],
          loading: false
        },
        competencias: {
          total: [],
          loading: false
        },
        indicadores: {
          total: [],
          loading: false
        }
      },
      fastComponentesAssociados: [],
      fastComponentesAssociadosLoading: true,
      anoBaseCalendario: new Date().getFullYear(),
      // Aula
      fastProgramacaoAula: {
        id_programacao_aula: 0,
        id_programa_turma_unidade: 0,
        conteudo_programado: "",
        conteudo_realizado: "",
        data_programado: "",
        data_realizado: "",
        id_plataforma: this.$route.params.id_plataforma,
        id_aula: "",
        id_calendario_turma: 0,
        tipo_aula: "",
        id_live: 0,
        id_turma: 0,
        id_curso: 0,
        avaliacao: false,
        live: false,
        hora_programada: null,
        hora_realizada: null
      },
      // Lista de Professores da plataforma
      fastProfessoresTotal: [],
      fastProfessoresFiltro: [],
      fastProfessoresPaginacao: [],
      fastProfessoresLoading: [],
      // Professores da programação
      fastProfessoresProgramacao: [],
      fastProfessoresProgramacaoLoading: true,
      fastProfessorProgramacao: {
        id_professor: 0,
        id_programacao_aula: 0
      },
      // Live
      fastLiveNova: {
        id_live: "",
        API_TOKEN: "",
        nr_maximo_sala: "",
        data_live: "",
        hora_live: "",
        id_usuario_tutor: "",
        id_usuario_moderador: "",
        detalhes: "",
        titulo_live: "",
        id_plataforma: 0,
        status: "",
        video_registro: "",
        duracao_live: "",
        acesso: "R",
        id_usuario_responsavel: "",
        tipo: "J",
        participantes: [],
      },
      // Lista de usuarios
      usuariosTotal: [],
      usuariosFiltro: [],
      pageOfUsuarios: [],
      usuariosTotalEditar: [],
      usuariosFiltroEditar: [],
      pageOfUsuariosEditar: [],
    };
  },
  mounted: function () {
    if (
      this.$route.params.id_plataforma &&
      this.$route.params.id_turma &&
      this.$route.params.id_programa_turma
    ) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getFastProgramas();
            this.getFastCursosPrograma();
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    // Turmas
    async getFastProgramas() {
      this.fastCursosProgramasLoading = true
      this.getFastTurma().then(async (turma) => {
        this.fastTurma = {
          ativo: turma.ativo,
          data_modifica: turma.data_modifica,
          data_registro: turma.data_registro,
          descricao_turma: turma.descricao_turma,
          etapa: turma.etapa,
          id_plataforma: turma.id_plataforma,
          id_turma: turma.id_turma,
          id_usuario: turma.id_usuario,
          id_usuario_modifica: turma.id_usuario_modifica,
          nome_plataforma: turma.nome_plataforma,
          sigla_turma: turma.sigla_turma,
          turno: turma.turno,
          id_secretaria_curso: turma.id_secretaria_curso,
          programas: [],
        };
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma_programa/lista?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_turma=" +
              this.$route.params.id_turma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            obj.forEach((e) => {
              if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
              if (e.data_final) e.data_final = e.data_final.split("T")[0];              
            });
            this.fastTurma.programas = obj;
          }
          this.$store.state.fastCarregando = false;
          this.fastCursosProgramasLoading = false
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false;
          this.fastCursosProgramasLoading = false
        }
      });
    },
    async getFastTurma() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma/lista?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_turma=" +
              this.$route.params.id_turma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            resolve(json[0]);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    async getFastCursosPrograma() {
      this.fastProgramaCursoEditar.alteracao_pendente = false;
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_turma_programa_unidade/lista?id_plataforma=" +
            this.retornaSessao(settings.fastSessaoPlataforma).id_plataforma +
            "&id_programa_turma=" +
            this.$route.params.id_programa_turma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          obj.forEach((e) => {
            if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
            if (e.data_final) e.data_final = e.data_final.split("T")[0];
          });
          this.fastCursosProgramas = obj;
        } else {
          this.fastCursosProgramas = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalEditarProgramaCurso(curso) {
      this.fastProgramaCursoEditar = curso;
      this.showModal("modalEditarProgramaCurso");
    },
    async editarProgramaCurso() {
      const fast_secretaria_turma_programa_unidade = {
        id_programa_turma_unidade: this.fastProgramaCursoEditar
          .id_programa_turma_unidade,
        data_inicio: this.fastProgramaCursoEditar.data_inicio,
        data_final: this.fastProgramaCursoEditar.data_final,
        id_programa_turma: this.$route.params.id_programa_turma,
        id_secretaria_curso_programa_unidade: this.fastProgramaCursoEditar.id_secretaria_curso_programa_unidade,
      };

      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_turma_programa_unidade/atualiza",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(fast_secretaria_turma_programa_unidade)
          )
        );

        let json = await resp.json();
        console.log("editarProgramaCurso", json);
        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        this.getFastCursosPrograma();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Curso editado com sucesso",
        });

        // Esconde modals
        this.hideModal('modalExibirCalendarioInicioUC')
        this.hideModal('modalExibirCalendarioFinalUC')
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    exibeProgramacaoAula(curso){
      this.fastProgramaCursoEditar = curso;  
      this.alteraFastNavegacao("exibirProgramacao")

      // Carrega aulas
      this.fastCursoAulas.loading = true
      this.promiseGetFastApi("api/fast_secretaria_turma_programacao_aula/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_turma=${this.fastTurma.id_turma}&id_curso=${curso.id_curso}`)
      .then(res => {
        console.log("api/fast_secretaria_turma_programacao_aula/lista", res)
        if (res.length) {
          res.forEach(e => {
            e.exibirDetalhes = false
            e.alteracao_pendente = false
            e.tipo_aula = e.tipo_aula ? e.tipo_aula : "S"
            e.professores = []
          } )
          this.fastCursoAulas.total = res
          this.fastCursoAulas.filtro = res
        }
        this.fastCursoAulas.loading = false
      })
      .catch(e => {
        this.fastCursoAulas.loading = false
        this.exibeToasty("Erro ao recuperar aulas", "error")
      })

      // Se possui plano de ensino carrega
      if (this.fastProgramaCursoEditar.id_plano_ensino) {        
        this.fastPlanoEnsino.id_plano_ensino = this.fastProgramaCursoEditar.id_plano_ensino
        this.promiseGetFastApi("api/secretaria_plano_ensino/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        .then(res => {
          console.log("res", res)  
          if (res.length) {
            this.fastPlanoEnsino = res[0]
          } else {
            this.exibeToasty("Erro ao recuperar plano de ensino", "error")
          }
        })
        .catch(e => this.exibeToasty(e, "error"))

        this.getComponenteAssociados()
      } else {
        this.exibeToasty("Nenhum plano de ensino está configurado para essa UC", "info");
        this.fastComponentesAssociados = []
        this.fastComponentesAssociadosLoading = false        
      }   
      
      // Busca professorres
      this.getProfessoresPlataforma(this.$route.params.id_plataforma)
    },
    filtraAulas(e) {
      let text = e.target.value;
      this.fastCursoAulas.filtro = this.fastCursoAulas.total.filter((e) => {
        return e.titulo_aula.toLowerCase().indexOf(text.toLowerCase()) != "-1";
      });
    },
    getComponenteAssociados(){
      this.fastComponentesAssociadosLoading = true
      this.fastComponente.bibliografias.loading = true
      this.fastComponente.competencias.loading = true
      this.fastComponente.indicadores.loading = true

      return new Promise(async (resolve, reject) => {
        // Lista componentes
        this.promiseGetFastApi("api/secretaria_plano_ensino/lista_componentes", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        // Lista bibliografias
        .then(res => {
          if (res.length) this.fastComponentesAssociados = res
          else this.fastComponentesAssociados = []          
          this.fastComponentesAssociadosLoading = false
          return this.promiseGetFastApi("api/secretaria_plano_ensino/lista_bibliografias", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        })
        // Lista competências
        .then(res => {
          if (res.length) this.fastComponente.bibliografias.total = res
          else this.fastComponente.bibliografias.total = []
          this.fastComponente.bibliografias.loading = false
          return this.promiseGetFastApi("api/secretaria_plano_ensino/lista_competencias", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        })
        // Lista indicadores
        .then(res => {
          if (res.length) this.fastComponente.competencias.total = res
          else this.fastComponente.competencias.total = []
          this.fastComponente.competencias.loading = false
          return this.promiseGetFastApi("api/secretaria_plano_ensino/lista_indicadores", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        })
        .then(res => {
          if (res.length) this.fastComponente.indicadores.total = res
          else this.fastComponente.indicadores.total = []
          this.fastComponente.indicadores.loading = false
          resolve(true)
        })
        .catch(e => {          
          reject("Erro ao retornar dados do plano de ensino")
        })
      });
    },
    exibirDetalhesAula(aula){
      aula.exibirDetalhes = !aula.exibirDetalhes  

      if (aula.exibirDetalhes) {
        if (aula.id_programacao_aula) this.getProfessoresProgramacao(aula)
        else 
          this.fastProfessoresProgramacaoLoading = false  
      }
      
    },
    definirDataInicioUC(cal){
      if (cal.date) {
        let data = cal.date
        let dia = data.split("/")[0].length == 1 ? "0" + data.split("/")[0] : data.split("/")[0]
        let mes = data.split("/")[1].length == 1 ? "0" + data.split("/")[1] : data.split("/")[1]
        let ano = data.split("/").pop()
        
        this.fastProgramaCursoEditar.data_inicio = `${ano}-${mes}-${dia}`
      }
      this.editarProgramaCurso()
    },
    definirDataFinalUC(cal){
      if (cal.date) {
        let data = cal.date
        let dia = data.split("/")[0].length == 1 ? "0" + data.split("/")[0] : data.split("/")[0]
        let mes = data.split("/")[1].length == 1 ? "0" + data.split("/")[1] : data.split("/")[1]
        let ano = data.split("/").pop()
        
        this.fastProgramaCursoEditar.data_final = `${ano}-${mes}-${dia}`
      }
      this.editarProgramaCurso()
    },
    atualizaProgramacaoAula(aula, professor){

      let fast_secretaria_turma_programacao_aula = {
        id_programacao_aula: aula.id_programacao_aula ? aula.id_programacao_aula : 0,
        id_programa_turma_unidade: this.fastProgramaCursoEditar.id_programa_turma_unidade,
        conteudo_programado: aula.conteudo_programado,
        conteudo_realizado: aula.conteudo_realizado,
        data_programado: aula.data_programado,
        data_realizado: aula.data_realizado,
        id_plataforma: this.$route.params.id_plataforma,
        id_aula: aula.id_aula_curso,
        id_calendario_turma: 0,
        tipo_aula: aula.tipo_aula,
        id_live: aula.id_live ? aula.id_live : 0,
        id_turma: this.$route.params.id_turma,
        id_curso: this.fastProgramaCursoEditar.id_curso,
        avaliacao: aula.avaliacao ? true : false,
        live: aula.live ? true : false,
        hora_programada: aula.hora_programada ? aula.hora_programada : null,
        hora_realizada: aula.hora_realizada ? aula.hora_realizada : null
      }
      
      this.promisePostFastApi(fast_secretaria_turma_programacao_aula, "api/fast_secretaria_turma_programacao_aula/insere").then((res) => {        
        if (res.length) {
            res.forEach(e => {
              if (e.id_aula == fast_secretaria_turma_programacao_aula.id_aula) {                
                aula.exibirDetalhes = true
                aula.alteracao_pendente = false
                aula.alteracao_pendente = false
                aula.id_programacao_aula = e.id_programacao_aula   

                if (professor) {
                  this.fastProgramacaoAula.id_programacao_aula = e.id_programacao_aula 
                  this.adicionaProfessorProgramacao(professor)
                }
              }
            })
        }

        this.exibeToasty("Alterações salvas com sucesso", "success")
        // Esconde modal
        this.hideModal('modalExibirCalendarioDataAula')
        
      }).catch((e) => {
          this.exibeToasty("Erro ao criar sala", "error");
      });
      
    },
    exibeModalExibirCalendarioDataAula(aula){
      this.fastProgramacaoAula = aula      
      this.showModal('modalExibirCalendarioDataAula')
    },
    definirDataAula(cal){
      
      if (cal.date) {
        let data = cal.date
        let dia = data.split("/")[0].length == 1 ? "0" + data.split("/")[0] : data.split("/")[0]
        let mes = data.split("/")[1].length == 1 ? "0" + data.split("/")[1] : data.split("/")[1]
        let ano = data.split("/").pop()  

        this.fastProgramacaoAula.data_programado = `${ano}-${mes}-${dia}`
        this.fastCursoAulas.total.forEach(e => {
            if (e.id_aula_curso && e.id_aula_curso == this.fastProgramacaoAula.id_aula_curso) {
              e.alteracao_pendente = true
              // Esconde modal
              this.hideModal('modalExibirCalendarioDataAula')
            }
        })
        //this.atualizaProgramacaoAula(this.fastProgramacaoAula)
      }
    },
    removerDataAula(){
      this.fastProgramacaoAula.data_programado = null
      this.fastCursoAulas.total.forEach(e => {
          if (e.id_aula_curso == this.fastProgramacaoAula.id_aula_curso) {
            e.alteracao_pendente = true
            e.data_programado = null
            // Esconde modal
            this.hideModal('modalExibirCalendarioDataAula')
          }
      })
    },
    // Professores
    async getProfessoresProgramacao(aula){
      this.fastProfessoresProgramacaoLoading = true
     
      this.promiseGetFastApi("api/fast_secretaria_turma_programacao_aula_professor/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_programacao_aula=${aula.id_programacao_aula}`).then(obj => {
        if (obj.length) {
          /*this.fastCursoAulas.total.forEach(a => {
            if (a.id_programacao_aula == id_programacao_aula) a.professores = obj
          })
          this.fastCursoAulas.filtro = this.fastCursoAulas.total*/
          aula.professores = obj
        }
        else {
          /*this.fastCursoAulas.total.forEach(a => {
            if (a.id_programacao_aula == id_programacao_aula) a.professores = []
          })
          this.fastCursoAulas.filtro = this.fastCursoAulas.total*/
          aula.professores = []
        }          
        this.fastProfessoresProgramacaoLoading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar Professores", "error");
        this.fastProfessoresProgramacaoLoading = false
      })
    },
    exibeModalProfessoresPlataforma(aula){
      this.fastProgramacaoAula = aula
      this.showModal('modalProfessoresPlataforma')
    },
    async getProfessoresPlataforma(id_plataforma){
      this.fastProfessoresLoading = true
     
      this.promiseGetFastApi("api/fast_plataforma_professor/lista_semcom_usuario", `id_plataforma=${id_plataforma}`).then(obj => {
        console.log("getProfessoresPlataforma", obj)
        if (obj.length) {
          this.fastProfessoresTotal = obj
          this.fastProfessoresFiltro = obj
        }
        else {
          this.fastProfessoresTotal = []
          this.fastProfessoresFiltro = []
        }          
        this.fastProfessoresLoading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar Professores", "error");
        this.fastProfessoresLoading = false
      })
    },
    filtraProfessores(e) {
      let text = e.target.value;
      this.fastProfessoresFiltro = this.fastProfessoresTotal.filter((e) => {
        return e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.email.toLowerCase().indexOf(text.toLowerCase()) != "-1";
      });
    },
    async adicionaProfessorProgramacao(professor){
      this.hideModal('modalProfessoresPlataforma')
      this.fastProfessoresProgramacaoLoading = true

      if (this.fastProgramacaoAula.id_programacao_aula) {
        const model = {
          fast_plataforma: {
            id_plataforma: this.$route.params.id_plataforma
          },
          fast_secretaria_turma_programacao_aula_professor: {
            id_programacao_aula: this.fastProgramacaoAula.id_programacao_aula,
            id_professor: professor.id_professor
          }
        }
        this.promisePostFastApi(model, "api/fast_secretaria_turma_programacao_aula_professor/insere").then((res) => { 
          if (res.length){
            this.exibeToasty("Professores inserido com sucesso", "success")  
            this.fastCursoAulas.total.forEach(a => {
              if (a.id_programacao_aula == this.fastProgramacaoAula.id_programacao_aula) a.professores = res
            })
          }  else {
            this.exibeToasty("Erro ao inserir professor", "error");
          } 
          this.fastProfessoresProgramacaoLoading = false      
        }).catch((e) => {
            this.exibeToasty("Erro ao inserir professor", "error");
            this.fastProfessoresProgramacaoLoading = false
        });
      } else {
        this.atualizaProgramacaoAula(this.fastProgramacaoAula, professor)
      }     

    },
    exibeModalExcluirProfessor(professor){
      this.fastProfessorProgramacao = professor
      this.showModal('modalExcluirProfessor')
    },
    async excluirProfessorProgramacao(){
      this.hideModal('modalExcluirProfessor')
      this.fastProfessoresProgramacaoLoading = true

      const model = {
        fast_plataforma: {
          id_plataforma: this.$route.params.id_plataforma
        },
        fast_secretaria_turma_programacao_aula_professor: {
          id_programacao_aula: this.fastProfessorProgramacao.id_programacao_aula,
          id_professor: this.fastProfessorProgramacao.id_professor
        }
      }

      this.promisePostFastApi(model, "api/fast_secretaria_turma_programacao_aula_professor/exclui").then((res) => { 
        if (res.length){            
          this.fastCursoAulas.total.forEach(a => {
            if (a.id_programacao_aula == this.fastProgramacaoAula.id_programacao_aula) a.professores = res
          })
        } else {
          this.fastCursoAulas.total.forEach(a => {   
            if (a.id_programacao_aula == this.fastProfessorProgramacao.id_programacao_aula) {              
              a.professores = []
            }
          })
        }

        this.exibeToasty("Professor inserido com sucesso", "success")
        this.fastProfessoresProgramacaoLoading = false      
      }).catch((e) => {
          this.exibeToasty("Erro ao remover professor", "error");
          this.fastProfessoresProgramacaoLoading = false
      });
    },
    // Calendário da turma
    exibeModalExibirCalendarioUC(curso){
      this.fastProgramaCursoEditar = curso;
      // Carrega aulas
      this.$store.state.fastCarregando = true
      this.promiseGetFastApi("api/fast_secretaria_turma_programacao_aula/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_turma=${this.fastTurma.id_turma}&id_curso=${curso.id_curso}`)
      .then(res => {        
        if (res.length) {          
          this.fastCursoAulas.total = res      
        }
        this.showModal('modalExibirCalendarioUC')
        this.$store.state.fastCarregando = false
      })
      .catch(e => {
        this.$store.state.fastCarregando = false
        this.exibeToasty("Erro ao recuperar aulas", "error")
      })

      
    },
    diaClicadoTurma(cal){
      console.log("diaClicadoTurma", cal)
    },
    // Live
    exibeModalCriarLive(aula){
      this.fastProgramacaoAula = aula
      let erros = []
      
      if (!aula.id_programacao_aula || !aula.data_programado) erros.push('É necessário definir a data programada')

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.getPlataformasUsuarios()

        if (!aula.hora_programada) {
          this.fastLiveNova.duracao_live = '23:59:59'
          this.fastLiveNova.hora_live = '00:00:00'
        } else {
          this.fastLiveNova.duracao_live = '04:00:00'
          this.fastLiveNova.hora_live = aula.hora_programada
        }
        this.fastLiveNova.titulo_live = aula.titulo_aula
        this.fastLiveNova.data_live = aula.data_programado.split("T")[0]

        this.showModal('modalCriarLive')
      }      
    },
    async getPlataformasUsuarios() {
      let param = `id_plataforma=${this.$route.params.id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=&responsavel=`
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_usuario/lista_usuarios_plataforma?" + param,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        //console.log("getPlataformasUsuarios", obj);
        if (obj.length > 0) {
          obj.forEach((e) => {
            if (!e.nome_usuario) e.nome_usuario = "Não cadastrado";
          });

          this.usuariosTotal = obj;
          this.usuariosFiltro = obj;
          this.usuariosTotalEditar = obj;
          this.usuariosFiltroEditar = obj;
        } else {
          this.usuariosTotal = [];
          this.usuariosFiltro = [];
          this.usuariosTotalEditar = [];
          this.usuariosFiltroEditar = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraUsuario(e) {
      let text = e.target.value;
      this.usuariosFiltro = this.usuariosTotal.filter(
        (e) => e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    adicionaParticipantesLive(usuario) {
      this.fastLiveNova.participantes.push(usuario);
    },
    verificaUsuarioIncluido(id_usuario) {
      let verificacao = this.fastLiveNova.participantes.filter(
        (e) => e.id_usuario == id_usuario
      );
      if (verificacao.length > 0) return true;
      else return false;
    },
    removeParticipantesLive(id_usuario) {
      this.fastLiveNova.participantes.forEach((u, index) => {
        if (u.id_usuario == id_usuario)
          this.fastLiveNova.participantes.splice(index, 1);
      });
    },
    filtraUsuarioEditar(e) {
      let text = e.target.value;
      this.usuariosFiltroEditar = this.usuariosTotalEditar.filter(
        (e) => e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    adicionaParticipantesLiveEditar(usuario) {
      this.fastLiveEditar.participantes.push(usuario);
    },
    verificaUsuarioIncluidoEditar(id_usuario) {
      let verificacao = this.fastLiveEditar.participantes.filter(
        (e) => e.id_usuario == id_usuario
      );
      if (verificacao.length > 0) return true;
      else return false;
    },
    removeParticipantesLiveEditar(id_usuario) {
      this.fastLiveEditar.participantes.forEach((u, index) => {
        if (u.id_usuario == id_usuario)
          this.fastLiveEditar.participantes.splice(index, 1);
      });
    },
    async criarLive() {
      let erros = [];
      if (!this.fastLiveNova.id_usuario_tutor)
        erros.push("A escolha do tutor é obrigatória");
      if (!this.fastLiveNova.titulo_live)
        erros.push("O título da live é obrigatório");
      if (!this.fastLiveNova.detalhes)
        this.fastLiveNova.detalhes = "Sem detalhes";
      if (!this.fastLiveNova.data_live)
        erros.push("O data da live é obrigatória");
      if (!this.fastLiveNova.hora_live)
        erros.push("O hora da live é obrigatória");
      if (!this.fastLiveNova.duracao_live)
        erros.push("O duração da live é obrigatória");
      this.fastLiveNova.nr_maximo_sala = 0;
      if (!this.fastLiveNova.participantes.length)
        erros.push("Ao menos um participante é obrigatório");

      if (this.fastLiveNova.tipo == "Z" && !this.fastUsuarioParametro.url_zoom)
        erros.push(
          "É necessário configurar a URL do Zoom para pode agendar esse tipo de live"
        );

      if (this.fastLiveNova.tipo == "Z")
        this.fastLiveNova.API_TOKEN = this.fastUsuarioParametro.url_zoom;

      if (this.fastLiveNova.tipo == "M" && !this.fastLiveNova.API_TOKEN)
        erros.push("É necessário inserir a URL do Google Meet");

      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      } else {
        this.fastLiveNova.id_usuario_moderador = this.fastLiveNova.id_usuario_tutor;
        this.fastLiveNova.id_plataforma = this.$route.params.id_plataforma;
        this.fastLiveNova.status = "A";
        let id_usuarios_participantes = this.fastLiveNova.participantes.map(
          (u) => u.id_usuario
        );
        let email_usuarios_participantes = this.fastLiveNova.participantes.map(
          (u) => u.email
        );
        let model = {
          fast_plataforma_live: this.fastLiveNova,
          id_usuarios_participantes: id_usuarios_participantes.join(),
          email_usuarios_participantes: email_usuarios_participantes.join(),
        };
        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_live/insere_nova",
            this.fastAjaxOptions("POST", JSON.stringify(model))
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.$store.state.fastCarregando = false;
          if (obj.length) {
            this.exibeToasty("Live agendada com sucesso", "success")
            this.fastLiveNova = {
              id_live: "",
              API_TOKEN: "",
              nr_maximo_sala: "",
              data_live: "",
              hora_live: "",
              id_usuario_tutor: "",
              id_usuario_moderador: "",
              detalhes: "",
              titulo_live: "",
              id_plataforma: 0,
              status: "",
              video_registro: "",
              duracao_live: "",
              acesso: "R",
              id_usuario_responsavel: "",
              tipo: "J",
              participantes: [],
            };
            this.hideModal("modalCriarLive");
            this.fastProgramacaoAula.id_live = obj[0].id_live
            this.atualizaProgramacaoAula(this.fastProgramacaoAula)
          } else {
            this.exibeToasty("Erro ao remover agendar live", "error")
          }
          
        
        } catch (e) {
          this.$store.state.fastCarregando = false;
          console.log("Erro", e);
        }
      }
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
  min-height: 100px;
}

/* Calendário */
.cal-feriado-marcado {
  background-color: #E10000 !important;
  color: #fff;
}
.cal-turma-marcado {
  background-color: #00e131 !important;
  color: #fff;
}
.cal-turma-conflito-marcado, .cal-feriado-conflito-marcado  {
  background-color: #fdba01 !important;
  color: #fff;
}

.fast-cursor-pointer:hover {
  background-color: #eee;
}
.fast-ano-selecionado {
  background-color: #eee;
}
.vfc-disabled {
  opacity: 0;
}
</style>
